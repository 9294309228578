:root {
    --primary-color: #012f81; /* Primary colors  #2626e6 */
    --secondary-color: #ffffff; /* Secondary color */
    --text-color: #656565; /* Text color for paragraphs */
}

/* Reset */
* {
    margin: 0; /* Reset margin for all elements */
    padding: 0; /* Reset padding for all elements */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

ul {
    padding: 0 !important;
}
/* 
p {
    padding: 0;
} */
/* General styling */
body {
    font-family: "Inter", sans-serif;
    overflow: hidden;
}

/* .logo {
    width: 185px;
} */

@keyframes slideIn {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.header-txt {
    font-weight: 700;
    font-size: 45px;
    padding-top: 20px;
    padding-bottom: 20px;
    opacity: 0; /* Start hidden */
    animation: slideIn 1s forwards; /* Add animation */
}

.parah-text {
    font-size: 14px;
    color: var(--text-color); /* Use variable for text color */
    opacity: 0; /* Start hidden */
    animation: slideIn 1s forwards; /* Add animation */
}

.parah-text-2 {
    font-size: 11px;
    color: var(--text-color); /* Use variable for text color */
    opacity: 0; /* Start hidden */
    animation: slideIn 1s forwards; /* Add animation */
}

.row-design {
    height: 100vh;
    opacity: 0; /* Start hidden */
    animation: slideIn 1s forwards; /* Add animation */
}

/* Image styling */
.rightImage {
    width: 100%;
    height: 100vh; /* Adjust height for better responsiveness */
    object-fit: cover;
}

.custom-button {
    width: 80%;
    padding: 13px 50px;
    background-color: var(--primary-color); /* Primary color */
    color: var(--secondary-color); /* Secondary color */
    border: 2px solid transparent; /* Set transparent border initially */
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.custom-button:hover {
    background-color: var(--secondary-color); /* Secondary color */
    color: var(--primary-color); /* Primary color */
    border: 2px solid var(--primary-color); /* Change border color on hover */
}

.secondary-button {
    width: 38%;
    padding: 13px 13px;
    background-color: var(--secondary-color); /* Secondary color */
    color: var(--primary-color); /* Primary color */
    border: 2px solid var(--primary-color); /* Primary color */
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.secondary-button:hover {
    background-color: var(--primary-color); /* Primary color */
    color: var(--secondary-color); /* Secondary color */
    border: none;
}

/* Container adjustments */
.container {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.first-row {
    display: flex;
    width: 100%;
}

.second-row {
    display: flex;
    width: 100%;
    margin-top: 10px;
    gap: 20px;
}

/* Sponsor logo styling */
.spon-logo {
    width: auto;
    height: auto;
}

/* Padding for list items */
ul li {
    padding-top: 5px;
}

ul {
    list-style: none;
}

.left-main {
    padding: 0 9.5% 0 9.5%;
}

.logo {
    width: 20rem;
}
/* Responsive adjustments */
@media (max-width: 768px) {
    .left-main {
        padding: 0px 10px;
    }

    .logo {
        width: 200px;
    }

    .header-txt {
        font-size: 2.5rem;
    }

    .parah-text {
        font-size: 0.875rem;
    }

    .custom-button,
    .secondary-button {
        width: 100%; /* Full-width buttons on small screens */
        padding: 10px;
        font-size: 14px;
    }

    .spon-logo {
        width: 80px; /* Adjust sponsor logo size for smaller screens */
    }
}

@media (max-width: 576px) {
    .logo {
        width: 200px;
    }
    .left-main {
        padding: 0px 10px;
    }
    .header-txt {
        font-size: 2rem;
    }

    .parah-text {
        font-size: 0.75rem;
    }

    .custom-button,
    .secondary-button {
        padding: 8px;
        font-size: 12px;
    }

    .spon-logo {
        width: 60px;
    }
}

.spon-logo-wrapper {
    position: relative; /* Needed to position hover image on top */
}

.spon-logo-hover {
    position: absolute; /* Position it on top of the original */
    top: 0; /* Align to top */
    left: 0; /* Align to left */
    opacity: 0; /* Hide initially */
    width: 100%; /* Ensures both images are the same size */
    height: 100%; /* Ensures both images are the same size */
    transition: opacity 0.3s ease; /* Smooth transition */
}

.spon-logo-hover {
    opacity: 0; /* Hover image is hidden initially */
}

.spon-logo-wrapper:hover .spon-logo {
    opacity: 0; /* Fade out the original image */
}

.spon-logo-wrapper:hover .spon-logo-hover {
    opacity: 1; /* Fade in the hover image */
}
